<template>
	<div>
		<CCard>
			<CCardBody class="card-gifts">
				<CustomTable :total-pages="totalPages" :total-items="totalItems" @search="search">
					<template #headers>
						<tr>
							<th
								v-for="(header, index1) in headers"
								:key="`${header.title}-${index1}`"
								scope="col"
								:class="`${header.class}`"
								>{{ header.title }}</th
							>
						</tr>
					</template>
					<template #body>
						<template v-if="products && products.length">
							<tr v-for="(item, index2) in products" :key="`${item.id}-${index2}`">
								<td scope="row" class="fit text-break text-pre-wrap">{{
									getTimeLocale(item.createdAt, (format = "DD/MM/YYYY"))
								}}</td>
								<td scope="row" class="fit table-no">
									<p v-for="(service, index3) in item.services" :key="`${index3}`">{{
										getServiceText(service)
									}}</p>
								</td>
								<td scope="row" class="fit text-center actions">
									<router-link
										:to="{ name: 'CustomerGiftDetail', params: { id: item.id } }"
										class="link-icon text-info"
										><span class="prefix-input d-inline-block"
											><font-awesome-icon
												:icon="['fas', 'eye']"
												:title="$t('Action.Detail')"
											/> </span
									></router-link>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr class="text-center">
								<td colspan="12" class="fit">
									{{ $t("Table.NoData") }}
								</td>
							</tr>
						</template>
					</template>
				</CustomTable>
				<CRow class="text-center">
					<CCol>
						<button type="button" class="btn btn-outline-danger ml-2" @click="cancel">
							{{ $t("Button.Exit") }}
						</button>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>
<script>
import { GET_GIFT_LIST } from "../store/action-types"
import { RESET_FILTERS_TABLE_DETAIL } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("customers")
import { PAGING } from "../../../shared/plugins/constants"

export default {
	name: "ReceiveFreeGifts",

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("CustomerGifts.GiftDate"),
					class: "text-nowrap",
				},
				{
					title: this.$t("CustomerGifts.Service"),
					class: "text-nowrap",
				},
				{
					title: this.$t("CustomerGifts.View"),
					class: "fit text-center",
				},
			],

			isCreateExamination: true,
			limit: PAGING.LIMIT,
			offset: PAGING.SKIP,
		}
	},

	computed: {
		...mapState(["products", "totalItems"]),

		totalPages() {
			return Math.ceil(this.totalItems / this.limit)
		},
	},

	created() {
		this.setData()
	},

	beforeDestroy() {
		this.RESET_FILTERS_TABLE_DETAIL()
	},

	methods: {
		...mapActions({
			GET_GIFT_LIST,
		}),
		...mapMutations({ RESET_FILTERS_TABLE_DETAIL }),

		setData: async function () {
			const params = {
				customerId: this.id,
				limit: this.limit,
				offset: this.offset,
			}
			await this.GET_GIFT_LIST({ params })
		},

		getNo(no) {
			return no + 1 + (+this.offset || 0)
		},

		getResult(getResult) {
			if (!getResult) return ""
			return this.$t(`RiskResult.${getResult}`)
		},

		getServiceText(service) {
			return this.$t(`ServiceText.${service}`)
		},

		getClinicText(clinic) {
			return clinic ? this.$t(`ClinicText.${clinic}`) : ""
		},

		search: async function (params = {}) {
			params.customerId = this.id
			await this.GET_GIFT_LIST({ params })
		},

		cancel: function () {
			this.$emit("cancel")
		},
	},
}
</script>

<style lang="scss">
.card-gifts {
	padding: 0;
	padding-bottom: 1.25rem;

	.custom-table .card-body {
		padding-bottom: 0;
	}
}
</style>
